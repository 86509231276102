import { useCallback, useRef, useState } from 'react'
import { FiGlobe } from 'react-icons/fi'
import { SupportedLocale, SUPPORTED_LOCALES, SwapWidget } from '@uniswap/widgets'

// ↓↓↓ Don't forget to import the widgets' fonts! ↓↓↓
import '@uniswap/widgets/fonts.css'
// ↑↑↑

import { useActiveProvider } from '../connectors'
import { JSON_RPC_URL } from '../constants'
import Web3Connectors from './Web3Connectors'
import styles from '../styles/Home.module.css'

const TOKEN_LIST = 'https://gateway.ipfs.io/ipns/tokens.uniswap.org'
const UNI = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'

const theme = {
  primary: '#FFF',
  secondary: '#FFF',
  container: '#4E4E5A',
  module: '#03312E',
  accent: '#222633',
}

const TOKENS = [
  {
    name: 'USR Coin',
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    symbol: 'USR',
    decimals: 18,
    chainId: 1,
    logoURI: 'https://swap.ceetio.com/assets/icon-usr.png',
  },
  {
    name: 'Iberian Governance',
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    symbol: 'IBE',
    decimals: 2,
    chainId: 1,
    logoURI: 'https://swap.ceetio.com/assets/logo-ibe.png',
  },
]

const USR = '0x6B175474E89094C44Da98b954EedeAC495271d0F'

const IBE = '0xdAC17F958D2ee523a2206206994597C13D831ec7'

export default function App() {
  // When a user clicks "Connect your wallet" in the SwapWidget, this callback focuses the connectors.
  const connectors = useRef<HTMLDivElement>(null)
  const focusConnectors = useCallback(() => connectors.current?.focus(), [])

  // The provider to pass to the SwapWidget.
  // This is a Web3Provider (from @ethersproject) supplied by @web3-react; see ./connectors.ts.
  const provider = useActiveProvider()

  // The locale to pass to the SwapWidget.
  // This is a value from the SUPPORTED_LOCALES exported by @uniswap/widgets.
  const [locale, setLocale] = useState<SupportedLocale>('en-EN')
  const onSelectLocale = useCallback((e) => setLocale(e.target.value), [])

  return (
    <div className={styles.container}>
      {/* 
      <div className={styles.i18n}>
        <label style={{ display: 'flex' }}>
          <FiGlobe />
        </label>
        <select onChange={onSelectLocale}>
          {SUPPORTED_LOCALES.map((locale) => (
            <option key={locale} value={locale}>
              {locale}
            </option>
          ))}
        </select>
      </div>
      */}

      <main className={styles.main}>
        {/* 
        <h1 className={styles.title}>Uniswap Swap Widget</h1>
        */}
        <img src="https://swap.ceetio.com/assets/logo-main@1000.png" className={styles.logo}></img>

        <div className={styles.demo}>
          <div className={styles.widget}>
            <SwapWidget
              theme={theme}
              jsonRpcEndpoint={JSON_RPC_URL}
              tokenList={TOKENS}
              provider={provider}
              locale={locale}
              onConnectWallet={focusConnectors}
              defaultInputTokenAddress={USR}
              defaultInputAmount="1"
              defaultOutputTokenAddress={IBE}
            />
          </div>
          <div className={styles.connectors} ref={connectors} tabIndex={-1}>
            <Web3Connectors />
          </div>
          <p>Install Metamask:</p>
          <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
            <img src="https://swap.ceetio.com/assets/metamask_logo.png" className={styles.logo}></img>
          </a>
        </div>
      </main>
    </div>
  )
}
